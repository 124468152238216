<style lang="scss">
@import '@/scss/variables.scss';
.add-payment-method-wrapper {
	/* stylelint-disable-next-line selector-class-pattern */
	.AcsPayments-iFrame,
	.account-number-iframe {
		width: 100% !important;
		height: 72px !important;
	}

	.account-number-iframe {
		margin-top: -16px;
	}

	.check-account-routing-example {
		max-height: 150px;
	}

	.documentation-requirements-link {
		color: $realm-blue;
	}
}
</style>

<template>
	<r-form ref="addPaymentMethodForm" :submitMethod="submitMethod" class="add-payment-method-wrapper">
		<v-container fluid class="px-0 input-grid">
			<v-row v-if="managingPaymentMethod" :dense="$vuetify.breakpoint.xsOnly">
				<v-col>
					<r-text-input v-model="addPaymentMethodFormState.nickname" label="Account Nickname" maxlength="50" outlined />
				</v-col>
			</v-row>

			<v-row v-if="showPaymentTypeSelect" :dense="$vuetify.breakpoint.xsOnly">
				<v-col>
					<v-btn-toggle v-model="addPaymentMethodFormState.accountType" mandatory>
						<v-btn value="CC" class="default-input-height">
							<v-icon class="mr-1">credit_card</v-icon>
							<span>{{ cardSelectTitle }}</span>
						</v-btn>
						<v-btn v-if="isallowAch" value="C" class="default-input-height">
							<v-icon class="mr-1">account_balance</v-icon>
							<span>Bank Account</span>
						</v-btn>
					</v-btn-toggle>
				</v-col>
			</v-row>

			<v-row :dense="$vuetify.breakpoint.xsOnly">
				<v-col v-show="!isCardPaymentMethod" :cols="$vuetify.breakpoint.xsOnly && 12">
					<r-text-input v-if="!isCardPaymentMethod" v-model="addPaymentMethodFormState.routingNumber" append-icon="info" :hint="bankName" type="number" label="Routing Number" maxlength="9" persistent-hint required outlined v-on:append="routingNumberInfoClicked" />
				</v-col>
				<v-col :cols="$vuetify.breakpoint.xsOnly && 12">
					<div class="mt-n1" v-html="accountNumberIFrameHtml"></div>
				</v-col>
				<v-col v-show="!isCardPaymentMethod && showCheckExample" cols="12">
					<img class="responsive-image check-account-routing-example mb-4" src="/Assets/SPA/images/giving/check-account-routing-example.png" />
				</v-col>
			</v-row>

			<v-row :dense="$vuetify.breakpoint.xsOnly">
				<v-col>
					<r-text-input v-model="addPaymentMethodFormState.billingName" :label="nameLabel" maxlength="150" autocomplete="cc-name" type="name" required outlined />
				</v-col>
			</v-row>

			<v-row v-if="isCardPaymentMethod" :dense="$vuetify.breakpoint.xsOnly">
				<v-col cols="6" :sm="cvv2Required ? 3 : 6">
					<r-select-list ref="expmonth" v-model="addPaymentMethodFormState.expMonth" :options="expMonths" label="Expiration Month" autocomplete="cc-exp-month" required outlined />
				</v-col>
				<v-col cols="6" :sm="cvv2Required ? 3 : 6">
					<r-select-list ref="expyear" v-model="addPaymentMethodFormState.expYear" :options="expYears" label="Expiration Year" autocomplete="cc-exp-year" required outlined />
				</v-col>
				<v-col v-if="cvv2Required" cols="12" sm="6">
					<r-text-input v-model="addPaymentMethodFormState.cvv2" hint="Security code on the back or front of your card" :minlength="cvvMinLength" :maxlength="4" type="number" label="CVV" :required="!givingFormStore.isAdminManaging()" outlined />
				</v-col>
			</v-row>

			<v-row v-if="billingCountries.length > 0" :dense="$vuetify.breakpoint.xsOnly">
				<v-col>
					<r-select-list ref="billingCountry" v-model="addPaymentMethodFormState.billingCountry" :options="billingCountries" label="Billing Country" autocomplete="country" required outlined />
				</v-col>
			</v-row>

			<v-row v-if="addPaymentMethodFormState.billingCountry === 'US' || addPaymentMethodFormState.billingCountry === 'CA'" :dense="$vuetify.breakpoint.xsOnly">
				<v-col cols="12" sm="6">
					<r-text-input v-model="addPaymentMethodFormState.billingAddress1" maxlength="50" label="Billing Address 1" autocomplete="address-line1" required outlined />
				</v-col>
				<v-col cols="12" sm="6">
					<r-text-input v-model="addPaymentMethodFormState.billingAddress2" maxlength="50" label="Billing Address 2" autocomplete="address-line2" outlined />
				</v-col>
			</v-row>

			<v-row :dense="$vuetify.breakpoint.xsOnly">
				<v-col :cols="$vuetify.breakpoint.xsOnly && 12">
					<r-text-input v-model="addPaymentMethodFormState.billingCity" maxlength="50" label="Billing City" autocomplete="address-level2" required outlined />
				</v-col>
				<v-col v-if="['US', 'CA'].includes(addPaymentMethodFormState.billingCountry)" :cols="$vuetify.breakpoint.xsOnly && 12">
					<r-select-list v-if="addPaymentMethodFormState.billingCountry === 'US'" ref="billingState" v-model="addPaymentMethodFormState.billingState" :options="billingStates" label="Billing State" autocomplete="address-level1" required outlined />
					<r-select-list v-if="addPaymentMethodFormState.billingCountry === 'CA'" ref="billingProvince" v-model="addPaymentMethodFormState.billingProvince" :options="billingProvinces" label="Billing Province" autocomplete="address-level1" required outlined />
				</v-col>
				<v-col v-if="['US', 'CA'].includes(addPaymentMethodFormState.billingCountry)" :cols="$vuetify.breakpoint.xsOnly && 12">
					<r-text-input v-model="addPaymentMethodFormState.billingPostalCode" :country="addPaymentMethodFormState.billingCountry" type="postalCode" label="Billing Postal Code" autocomplete="postal-code" required outlined />
				</v-col>
			</v-row>
		</v-container>

		<v-layout wrap>
			<div :class="{ 'mb-4': $vuetify.breakpoint.xs }" v-html="recaptchaHtml"></div>

			<v-flex v-if="givingFormStore.isAdminManaging()" class="pb-0 pt-0" xs12>
				<v-checkbox v-model="legalFulfilled" :rules="[v => !!v || '']" class="mt-0 pt-0" required>
					<div slot="label">
						I understand and agree to the&nbsp;
						<a href="https://acstd.portal.heretto.com/en/trust-center/security-tips/entering-gifts-or-payments-on-behalf-of-someone-else" target="_blank" class="documentation-requirements-link" @click.stop>documentation requirements</a>
						&nbsp;for managing payment methods.
					</div>
				</v-checkbox>
			</v-flex>
			<v-flex v-if="!this.transactionState.transaction.isRecurrence && this.isAuthEnabled && !managingPaymentMethod" class="pb-0 pt-0" xs12>
				<v-checkbox v-model="addPaymentMethodFormState.savePaymentMethod" label="Save for future use." class="mt-0 pt-0"></v-checkbox>
			</v-flex>
		</v-layout>

		<!-- <r-text-input v-model="addPaymentMethodFormState.emailAddress" label="Billing Email" autocomplete="email" required></r-text-input> -->

		<r-btn class="hidden-off-screen" type="submit"></r-btn>
	</r-form>
</template>

<script>
import cssVariables from '@/scss/variables.scss';

export default {
	props: {
		submitMethod: {
			type: Function,
			required: true
		},
		managingPaymentMethod: {
			type: Boolean,
			default: false
		},
		editingPaymentMethod: {
			type: Object,
			default: null
		}
	},
	data: function() {
		return {
			accountState: _REALM.STORE.accountStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			givingFormStore: _REALM.STORE.givingFormStore,
			givingFormState: _REALM.STORE.givingFormStore.state,
			transactionStore: _REALM.STORE.transactionStore,
			addPaymentMethodPromise: {},
			loaded: false,
			legalFulfilled: false,
			addPaymentMethodFormState: _REALM.STORE.paymentMethodStore.state.addPaymentMethodForm,
			baseData: {
				solutionid: null,
				merchantid: null,
				customerid: null,
				newcustomer: null,
				originalPaymentMethodId: null,
				addedByStaff: null,
				isdebitcardonly: null,
				sessionid: null,
				nvpvar: null,
				acsrequestid: null,
				allowVisa: null,
				allowMasterCard: null,
				allowDiscover: null,
				allowAmericanExpress: null,
				siteKey: null
			},
			accountTypes: [],
			expMonths: [],
			expYears: [],
			billingCountries: [],
			billingStates: [],
			billingProvinces: [],
			bankName: null,
			accountNumberIFrameHtml: null,
			recaptchaHtml: null,
			cvv2Required: false,
			showCheckExample: false
		};
	},
	computed: {
		nameLabel() {
			return _REALM.STORE.paymentMethodStore.state.addPaymentMethodForm.accountType === 'CC' ? 'Name On Card' : 'Billing Name';
		},
		isGivingFormView() {
			return _REALM.VM.$route.params.givingFormUrl !== undefined;
		},
		isallowAch() {
			return _REALM.STORE.givingStore.state.settings.allowAch;
		},
		isCardPaymentMethod() {
			return this.addPaymentMethodFormState.accountType === 'CC';
		},
		showPaymentTypeSelect() {
			if (_REALM.STORE.givingStore.state.settings.allowDebitCard && !_REALM.STORE.givingStore.state.settings.allowCreditCard) {
				return true;
			} else {
				return (_REALM.STORE.givingStore.state.settings.allowCreditCard || _REALM.STORE.givingStore.state.settings.allowDebitCard) && _REALM.STORE.givingStore.state.settings.allowAch;
			}
		},
		cardSelectTitle() {
			if (_REALM.STORE.givingStore.state.settings.allowCreditCard) {
				return 'Credit or Debit';
			} else {
				return 'Debit';
			}
		},
		cvvMinLength() {
			if (this.givingFormStore.isAdminManaging() && !this.addPaymentMethodFormState.cvv2) {
				return 0;
			}
			return 3;
		},
		isAuthEnabled() {
			return _REALM.STORE.givingFormStore.isAuthEnabled();
		}
	},
	watch: {
		'addPaymentMethodFormState.accountType'(value) {
			this.accountTypeChanged(value);
		},
		//'addPaymentMethodFormState.routingNumber'(value) {
		//	this.routingNumberChanged(value);
		//},
		'addPaymentMethodFormState.billingName'(value) {
			_REALM.STORE.givingFormStore.state.transactionAccount.label = value;
		},
		'givingFormState.transactionAccount.primaryEmail'(value) {
			this.addPaymentMethodFormState.emailAddress = value;
		},
		'transactionState.transaction.isRecurrence'(value) {
			if (value) {
				this.addPaymentMethodFormState.savePaymentMethod = true;
			}
		}
	},
	created: function() {
		_REALM.STORE.paymentMethodStore.resetAddPaymentMethodFormState();

		if (this.editingPaymentMethod) {
			this.setFieldsFromPaymentMethod();
		} else {
			this.setFieldsFromAccount();
		}

		const paymentMethodId = this.editingPaymentMethod ? this.editingPaymentMethod.paymentMethodId : null;
		_REALM.STORE.mintProxyStore
			.getAddPaymentMethodForm(paymentMethodId)
			.then(this.initializeInjectionForm)
			.catch(function() {
				_REALM.STORE.givingFormStore.state.currentDonorPage = 'LandingPage';
			});
		window.recaptchaCallback = this.recaptchaLoaded;

		if (!this.isAuthEnabled && !this.transactionState.transaction.isRecurrence) {
			this.addPaymentMethodFormState.savePaymentMethod = false;
		} else if (this.managingPaymentMethod) {
			this.addPaymentMethodFormState.savePaymentMethod = true;
		}
	},
	destroyed() {
		if (window.removeEventListener) {
			window.removeEventListener('message', this.listener, false);
		}
	},
	methods: {
		routingNumberInfoClicked() {
			this.showCheckExample = !this.showCheckExample;
		},
		setFieldsFromPaymentMethod() {
			const paymentMethod = this.editingPaymentMethod;
			if (paymentMethod) {
				const isCreditDebit = paymentMethod.paymentMethodType === 'Credit' || paymentMethod.paymentMethodType === 'Debit';
				this.addPaymentMethodFormState.accountType = isCreditDebit ? 'CC' : 'C';
				this.addPaymentMethodFormState.nickname = paymentMethod.description;
				this.addPaymentMethodFormState.emailAddress = paymentMethod.billingEmail;
				this.addPaymentMethodFormState.billingName = paymentMethod.billingName;
				const transactionAccountCountry = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress ? _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.country : null;
				this.addPaymentMethodFormState.billingCountry = paymentMethod.billingCountryCode || transactionAccountCountry || _REALM.STORE.siteStore.state.site.defaultCountry;
				this.addPaymentMethodFormState.billingAddress1 = paymentMethod.billingAddress1;
				this.addPaymentMethodFormState.billingaddress2 = paymentMethod.billingaddress2;
				this.addPaymentMethodFormState.billingCity = paymentMethod.billingCity;
				this.addPaymentMethodFormState.billingState = paymentMethod.billingRegion;
				this.addPaymentMethodFormState.billingProvince = paymentMethod.billingRegion;
				this.addPaymentMethodFormState.billingPostalCode = paymentMethod.billingPostalCode;
				if (isCreditDebit) {
					this.addPaymentMethodFormState.expMonth = paymentMethod.expirationMonth.toString().padStart(2, '0');
					this.addPaymentMethodFormState.expYear = paymentMethod.expirationYear.toString();
				}
			}
		},
		setFieldsFromAccount() {
			this.addPaymentMethodFormState.emailAddress = _REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail;
			this.addPaymentMethodFormState.billingName = _REALM.STORE.givingFormStore.state.transactionAccount.label;
			if (_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress) {
				this.addPaymentMethodFormState.billingCountry = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.country ? _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.country : _REALM.STORE.siteStore.state.site.defaultCountry;
				this.addPaymentMethodFormState.billingAddress1 = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.address1;
				this.addPaymentMethodFormState.billingAddress2 = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.address2;
				this.addPaymentMethodFormState.billingCity = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.city;
				this.addPaymentMethodFormState.billingState = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.region;
				this.addPaymentMethodFormState.billingProvince = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.region;
				this.addPaymentMethodFormState.billingPostalCode = _REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.postalCode;
			} else {
				this.addPaymentMethodFormState.billingCountry = _REALM.STORE.siteStore.state.site.defaultCountry;
			}
		},
		initializeInjectionForm(resp) {
			var self = this;

			var injectionDiv = document.createElement('div');
			injectionDiv.innerHTML = resp;

			// transfer select options
			self.setSelectOptions(injectionDiv.querySelector('select#AcsPayments_vanco_accounttype'), self.accountTypes);
			self.setSelectOptions(injectionDiv.querySelector('#cxm'), self.expMonths);
			self.setSelectOptions(injectionDiv.querySelector('#cxy'), self.expYears);
			self.setSelectOptions(injectionDiv.querySelector('#AcsPayments_vanco_billingcountrycode'), self.billingCountries);
			self.setSelectOptions(injectionDiv.querySelector('#AcsPayments_vanco_billingstate'), self.billingStates);
			self.setSelectOptions(injectionDiv.querySelector('#AcsPayments_vanco_billingprovince'), self.billingProvinces);

			// set defaults
			if (self.accountTypes.length > 0 && self.addPaymentMethodFormState.accountType === null) {
				self.addPaymentMethodFormState.accountType = self.accountTypes[0].value;
			}

			// set base data from form
			self.baseData.solutionid = injectionDiv.querySelector('#AcsPayments_vanco_solutionid').value;
			self.baseData.merchantid = injectionDiv.querySelector('#AcsPayments_vanco_merchantid').value;
			self.baseData.customerid = injectionDiv.querySelector('#AcsPayments_vanco_customerid').value;
			self.baseData.newcustomer = injectionDiv.querySelector('#AcsPayments_vanco_newcustomer').value === 'True';
			self.baseData.originalPaymentMethodId = injectionDiv.querySelector('#AcsPayments_vanco_originalPaymentMethodId').value;
			self.baseData.addedByStaff = injectionDiv.querySelector('#AcsPayments_vanco_addedByStaff').value === 'True';
			self.baseData.isdebitcardonly = injectionDiv.querySelector('#AcsPayments_vanco_isdebitcardonly').value === 'YES';
			self.baseData.sessionid = injectionDiv.querySelector('#AcsPayments_vanco_sessionid').value;
			self.baseData.nvpvar = injectionDiv.querySelector('#AcsPayments_vanco_nvpvar').value;
			self.baseData.acsrequestid = injectionDiv.querySelector('#AcsPayments_vanco_acsrequestid').value;
			self.baseData.allowVisa = injectionDiv.querySelector('#allowVisa').value === 'True';
			self.baseData.allowMasterCard = injectionDiv.querySelector('#allowMasterCard').value === 'True';
			self.baseData.allowDiscover = injectionDiv.querySelector('#allowDiscover').value === 'True';
			self.baseData.allowAmericanExpress = injectionDiv.querySelector('#allowAmericanExpress').value === 'True';

			// handle cvv2 when it exists
			var cvv2 = injectionDiv.querySelector('#cvc');
			if (cvv2) {
				self.cvv2Required = true;
			}

			// handle recaptcha when it exists
			var recaptcha = injectionDiv.querySelector('#recaptcha');
			if (recaptcha) {
				self.baseData.siteKey = injectionDiv.querySelector('#siteKey').value;
				self.setupRecaptcha(recaptcha);
			}

			var ele = injectionDiv.querySelector('#AcsPayments_vanco_accountnumber_iFrame');
			self.accountNumberIFrameHtml = ele.outerHTML;

			self.initIFrame();
		},
		setSelectOptions(ele, target) {
			if (ele && ele.options && ele.options.length) {
				for (var i = 0; i < ele.options.length; i++) {
					if (ele.options[i].value) {
						target.push({
							value: ele.options[i].value,
							text: ele.options[i].text
						});
					}
				}
			} else if (ele && ele.value) {
				target.push({
					value: ele.value,
					text: ele.options[i].text
				});
			}
		},
		injectScript(src) {
			var recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', src);
			recaptchaScript.async = true;
			document.head.appendChild(recaptchaScript);
		},
		setupRecaptcha(recaptcha) {
			var self = this;

			self.injectScript('https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit');
			self.recaptchaHtml = recaptcha.outerHTML;
		},
		recaptchaLoaded() {
			var self = this;
			// eslint-disable-next-line no-undef
			grecaptcha.render('recaptcha', {
				sitekey: self.baseData.siteKey,
				size: 'invisible',
				badge: self.$vuetify.breakpoint.xsOnly ? 'inline' : 'bottomright',
				callback: function() {
					self.recaptchaCompleted();
				}
			});
		},
		recaptchaOnPage() {
			return document.querySelector('#recaptcha') !== null;
		},
		recaptchaCompleted() {
			var self = this;
			_REALM.LOADER.show();
			// eslint-disable-next-line no-undef
			_REALM.STORE.mintProxyStore.cacheValue(self.baseData.acsrequestid, grecaptcha.getResponse()).then(function() {
				self.submit();
				try {
					if (self.recaptchaOnPage()) {
						// eslint-disable-next-line no-undef
						grecaptcha.reset();
					}
				} catch (e) {
					// ignore errors
				}
			});
		},
		initIFrame() {
			var self = this;

			if (window.addEventListener) {
				window.addEventListener('message', self.listener, false);
			} else {
				window.attachEvent('onmessage', self.listener);
			}
		},
		iFrameLoaded() {
			var self = this;
			self.jsonPostMessage('setupIFrame', {
				accountType: self.addPaymentMethodFormState.accountType,
				allowVisa: self.baseData.allowVisa,
				allowMasterCard: self.baseData.allowMasterCard,
				allowDiscover: self.baseData.allowDiscover,
				allowAmericanExpress: self.baseData.allowAmericanExpress,
				darkTheme: self.givingFormState.givingForm.useDarkBodyScheme,
				theme: {
					primary: self.givingFormState.givingForm.useDarkBodyScheme ? cssVariables['color-white'] : cssVariables['color-black'],
					secondary: self.givingFormState.givingForm.buttonColor,
					accent: self.givingFormState.givingForm.buttonColor,
					success: cssVariables['color-success'],
					error: self.givingFormState.givingForm.useDarkBodyScheme ? cssVariables['color-error-dark-theme'] : cssVariables['color-error'],
					warning: cssVariables['color-warning'],
					info: self.givingFormState.givingForm.buttonColor
				}
			});

			self.loaded = true;
		},
		listener(event) {
			var self = this;

			if (typeof event.data !== 'string' || event.data === 'recaptcha-setup') {
				return;
			}

			try {
				var eventData = JSON.parse(event.data);
			} catch (e) {
				console.error('Failed to parse data from message:', e);
			}

			if (eventData && eventData.eventType) {
				if (eventData.eventType == 'loaded') {
					self.iFrameLoaded();
				} else if (eventData.eventType == 'updateDefaultNickname') {
					self.updateDefaultNickname(eventData);
				} else if (eventData.eventType == 'vancoPostResult') {
					self.handleVancoPost(eventData.data);
				}
			}
		},
		updateDefaultNickname(data) {
			var self = this;

			if (!self.addPaymentMethodFormState.nickname || !self.managingPaymentMethod) {
				self.addPaymentMethodFormState.nickname = data.defaultNickname;
			}
		},
		handleVancoPost(data) {
			var self = this;
			if (!data.Success || !data.Data || !data.Data.PaymentMethodId) {
				// var msg = data.Data;
				// if (data.Error) {
				// 	msg += ' - ' + data.Error;
				// }
				var error = data.Error;
				if (!error && data.Data.length > 0) {
					error = data.Data[0].Message;
				}

				if (!error || typeof error !== 'string') {
					error = 'An error occured while adding a payment method.';
				}

				_REALM.TOAST.error(error);

				if (self.addPaymentMethodPromise.reject) {
					self.addPaymentMethodPromise.reject(error);
				}

				return;
			}

			if (self.addPaymentMethodPromise.resolve) {
				self.addPaymentMethodPromise.resolve(data.Data);
			}
		},
		resetAddVariables() {
			var self = this;
			return _REALM.STORE.mintProxyStore.getAddPaymentMethodFormVariables().then(function(resp) {
				self.baseData.nvpvar = resp.nvpvar;
				self.baseData.acsrequestid = resp.acsRequestId;
			});
		},
		accountTypeChanged() {
			var self = this;
			self.jsonPostMessage('accountTypeChanged', {
				accountType: self.addPaymentMethodFormState.accountType
			});
		},
		routingNumberChanged() {
			var self = this;
			if (self.addPaymentMethodFormState.routingNumber && self.addPaymentMethodFormState.routingNumber.length == 9) {
				_REALM.STORE.mintProxyStore.getBankName(self.addPaymentMethodFormState.routingNumber).then(function(resp) {
					self.bankName = resp;
				});
			}
		},
		addPaymentMethod() {
			var self = this;
			return new Promise(function(resolve, reject) {
				self.addPaymentMethodPromise.resolve = resolve;
				self.addPaymentMethodPromise.reject = reject;
				self.resetAddVariables().then(function() {
					self.submit();
				});
			});
		},
		validate() {
			return this.$refs.addPaymentMethodForm.validate();
		},
		submit() {
			var self = this;
			if (self.validate()) {
				// eslint-disable-next-line no-undef
				if (self.recaptchaHtml != null && grecaptcha.getResponse() == '') {
					// recaptcha is enabled on this form, so we need to execute it first
					_REALM.LOADER.hide();
					// eslint-disable-next-line no-undef
					grecaptcha.execute();
				} else {
					self.getMintCustomerId().then(function(mintCustomerId) {
						var data = {
							solutionid: self.baseData.solutionid,
							merchantid: self.baseData.merchantid,
							customerid: mintCustomerId,
							newcustomer: self.baseData.newcustomer,
							originalPaymentMethodId: self.baseData.originalPaymentMethodId,
							addedByStaff: _REALM.STORE.givingFormStore.isAdminManaging(),
							addedByGuest: !_REALM.STORE.accountStore.isLoggedIn(),
							isdebitcardonly: self.baseData.isdebitcardonly,
							isTextPaymentMethod: _REALM.STORE.givingFormStore.isTextGiver(),
							sessionid: self.baseData.sessionid,
							nvpvar: self.baseData.nvpvar,
							acsrequestid: self.baseData.acsrequestid,
							phonenumber: self.givingFormState.transactionAccount.phoneNumber,
							accounttype: self.addPaymentMethodFormState.accountType,
							expmonth: self.addPaymentMethodFormState.expMonth,
							expyear: self.addPaymentMethodFormState.expYear,
							routingnumber: self.addPaymentMethodFormState.routingNumber,
							billingName: self.addPaymentMethodFormState.billingName,
							billingaddr1: self.addPaymentMethodFormState.billingAddress1,
							billingaddr2: self.addPaymentMethodFormState.billingAddress2,
							billingcity: self.addPaymentMethodFormState.billingCity,
							billingzip: self.addPaymentMethodFormState.billingPostalCode,
							billingcountrycode: self.addPaymentMethodFormState.billingCountry,
							email: self.addPaymentMethodFormState.emailAddress,
							savepaymentmethod: self.addPaymentMethodFormState.savePaymentMethod
						};

						if (self.addPaymentMethodFormState.nickname) {
							data.nickname = self.addPaymentMethodFormState.nickname;
						}

						if (self.addPaymentMethodFormState.billingCountry == 'CA') {
							data.billingstate = self.addPaymentMethodFormState.billingProvince; // Vanco looks at billingstate regardless of country
						} else {
							data.billingstate = self.addPaymentMethodFormState.billingState;
						}

						if (self.cvv2Required && self.addPaymentMethodFormState.cvv2) {
							data.cvv2 = self.addPaymentMethodFormState.cvv2;
						}

						self.jsonPostMessage('submitToVanco', data);
					});
				}
			} else {
				self.addPaymentMethodPromise.reject('Error occured adding payment method');
			}
		},
		getMintCustomerId() {
			var self = this;
			return new Promise(function(resolve) {
				if (self.givingFormState.transactionAccount.mintCustomerId) {
					return resolve(self.givingFormState.transactionAccount.mintCustomerId);
				}

				// unauthenticated, so add a new random guid and it will be handled in the back-end
				self.givingFormState.transactionAccount.mintCustomerId = _REALM.TOOLS.newGuid();
				resolve(self.givingFormState.transactionAccount.mintCustomerId);
			});
		},
		jsonPostMessage(eventType, data) {
			var iFrame = document.getElementById('AcsPayments_vanco_accountnumber_iFrame');
			if (iFrame) {
				iFrame.contentWindow.postMessage(
					JSON.stringify({
						eventType,
						data
					}),
					'*'
				);
			}
		}
	}
};
</script>
